<template>
  <CModal
    :title="agency.Id ? 'Cập nhật CH' : 'Thêm CH'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="xl"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="4">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-info" />
                Thông tin cơ bản
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <CInput
                      label="Tên cửa hàng*"
                      placeholder="Tên cửa hàng"
                      v-model="agency.Name"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.Name'],
                      }"
                      :invalid-feedback="inValidObject['agency.Name']"
                    />
                  </CCol>
                  <CCol md="12">
                    <CTextarea
                      rows="2"
                      label="Địa chỉ"
                      placeholder="Địa chỉ"
                      v-model="agency.Address"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.Address'],
                      }"
                      :invalid-feedback="inValidObject['agency.Address']"
                    />
                  </CCol>
                  <CCol md="12">
                    <CSelect
                      label="Loại hình"
                      placeholder="Chọn Loại hình"
                      :value.sync="agency.AgencyType"
                      :options="
                        Object.keys($const.AGENCY_TYPES_TEXT).map((_) => {
                          return {
                            value: _,
                            label: $const.AGENCY_TYPES_TEXT[_],
                          };
                        })
                      "
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.AgencyType'],
                      }"
                      :invalid-feedback="inValidObject['agency.AgencyType']"
                    />
                  </CCol>
                  <CCol md="12">
                    <CSelect
                      label="Trạng thái"
                      placeholder="Chọn Trạng thái"
                      :value.sync="agency.Status"
                      :options="
                        Object.keys($const.STATUS_TEXT).map((_) => {
                          return {
                            value: _,
                            label: $const.STATUS_TEXT[_],
                          };
                        })
                      "
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.Status'],
                      }"
                      :invalid-feedback="inValidObject['agency.Status']"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="8">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-notes" />
                Thông tin hóa đơn
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <CInput
                      label="Tên đầy đủ"
                      placeholder="Tên đầy đủ"
                      v-model="agency.FullName"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.FullName'],
                      }"
                      :invalid-feedback="inValidObject['agency.FullName']"
                    />
                  </CCol>
                  <CCol col="6">
                    <CInput
                      label="Mã số thuế"
                      placeholder="Mã số thuế"
                      v-model="agency.TaxCode"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.TaxCode'],
                      }"
                      :invalid-feedback="inValidObject['agency.TaxCode']"
                    />
                  </CCol>
                  <CCol col="6">
                    <CInput
                      label="Số điện thoại"
                      placeholder="Số điện thoại"
                      v-model="agency.Phone"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.Phone'],
                      }"
                      :invalid-feedback="inValidObject['agency.Phone']"
                    />
                  </CCol>
                  <CCol md="12">
                    <CInput
                      label="Thông tin tài khoản"
                      placeholder="0014101668888888 - NGUYEN THI HANG - Ngan Hang TMCP Phuong Dong (OCB)"
                      v-model="agency.AcInfo"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['agency.AcInfo'],
                      }"
                      :invalid-feedback="inValidObject['agency.AcInfo']"
                    />
                  </CCol>
                </CRow>
                <div class="font-italic">
                  Lưu ý: Thông tin hóa đơn phục vụ mục đích in Hóa Đơn Bán Hàng,
                  vui lòng cung cấp thông tin đầy đủ và chính xác!
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveAgency" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["editing", "agencyId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      agency: this.newAgency(),
      saving: false,
    };
  },
  watch: {
    async agencyId(val) {
      await this.loadAgencyInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:agencyId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  methods: {
    newAgency() {
      return {
        Id: 0,
        Name: null,
        Address: null,
        AgencyType: this.$const.AGENCY_TYPES.KGroup.toString(),
        Status: this.$const.STATUS.Active.toString(),
        TaxCode: null,
        FullName: null,
        Phone: null,
        AcInfo:
          "0014101668888888 - NGUYEN THI HANG - Ngan Hang TMCP Phuong Dong (OCB)",
      };
    },

    async loadAgencyInfo(agencyId) {
      try {
        this.inValidObject = {};
        if (agencyId) {
          let filters = [];
          agencyId && filters.push(`Id eq ${agencyId}`);
          let resp = await this.$http.get(`odata/Agency`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select:
                "Id,Name,Address,AgencyType,Status,TaxCode,FullName,Phone,AcInfo",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.agency = {
              Id: _.Id,
              Name: _.Name,
              Address: _.Address,
              Status: _.Status.toString(),
              TaxCode: _.TaxCode,
              FullName: _.FullName,
              Phone: _.Phone,
              AcInfo: _.AcInfo,
              AgencyType: _.AgencyType.toString(),
            };
          }
        } else {
          this.agency = this.newAgency();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveAgency() {
      let agency = this.agency;
      this.inValidObject = this.checkValid(agency);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let agencyData = {
          Name: agency.Name,
          Address: agency.Address,
          Status: parseInt(agency.Status),
          TaxCode: agency.TaxCode,
          FullName: agency.FullName,
          Phone: agency.Phone,
          AcInfo: agency.AcInfo,
          AgencyType: parseInt(agency.AgencyType),
        };
        // Customer
        if (!agency.Id) {
          // post
          let resp = await this.$http.post(`odata/Agency`, agencyData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Agency/${agency.Id}`,
            agencyData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(agency) {
      let inValidObject = {};

      if (!agency.Name)
        inValidObject["agency.Name"] = "Vui lòng nhập Tên Cửa Hàng!";
      if (!agency.Address)
        inValidObject["agency.Address"] = "Vui lòng nhập Địa chỉ!";
      if (!agency.Status)
        inValidObject["agency.Status"] = "Vui lòng chọn Trạng Thái!";
      if (!agency.AgencyType)
        inValidObject["agency.AgencyType"] = "Vui lòng nhập Loại hình!";

      return inValidObject;
    },
  },
};
</script>
