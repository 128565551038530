var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.agency.Id ? 'Cập nhật CH' : 'Thêm CH',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"xl"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-info"}}),_vm._v(" Thông tin cơ bản ")],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên cửa hàng*","placeholder":"Tên cửa hàng","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.Name'],
                    },"invalid-feedback":_vm.inValidObject['agency.Name']},model:{value:(_vm.agency.Name),callback:function ($$v) {_vm.$set(_vm.agency, "Name", $$v)},expression:"agency.Name"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"rows":"2","label":"Địa chỉ","placeholder":"Địa chỉ","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.Address'],
                    },"invalid-feedback":_vm.inValidObject['agency.Address']},model:{value:(_vm.agency.Address),callback:function ($$v) {_vm.$set(_vm.agency, "Address", $$v)},expression:"agency.Address"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Loại hình","placeholder":"Chọn Loại hình","value":_vm.agency.AgencyType,"options":Object.keys(_vm.$const.AGENCY_TYPES_TEXT).map(function (_) {
                        return {
                          value: _,
                          label: _vm.$const.AGENCY_TYPES_TEXT[_],
                        };
                      }),"add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.AgencyType'],
                    },"invalid-feedback":_vm.inValidObject['agency.AgencyType']},on:{"update:value":function($event){return _vm.$set(_vm.agency, "AgencyType", $event)}}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.agency.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                        return {
                          value: _,
                          label: _vm.$const.STATUS_TEXT[_],
                        };
                      }),"add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.Status'],
                    },"invalid-feedback":_vm.inValidObject['agency.Status']},on:{"update:value":function($event){return _vm.$set(_vm.agency, "Status", $event)}}})],1)],1)],1)],1)],1),_c('CCol',{attrs:{"md":"8"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-notes"}}),_vm._v(" Thông tin hóa đơn ")],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên đầy đủ","placeholder":"Tên đầy đủ","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.FullName'],
                    },"invalid-feedback":_vm.inValidObject['agency.FullName']},model:{value:(_vm.agency.FullName),callback:function ($$v) {_vm.$set(_vm.agency, "FullName", $$v)},expression:"agency.FullName"}})],1),_c('CCol',{attrs:{"col":"6"}},[_c('CInput',{attrs:{"label":"Mã số thuế","placeholder":"Mã số thuế","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.TaxCode'],
                    },"invalid-feedback":_vm.inValidObject['agency.TaxCode']},model:{value:(_vm.agency.TaxCode),callback:function ($$v) {_vm.$set(_vm.agency, "TaxCode", $$v)},expression:"agency.TaxCode"}})],1),_c('CCol',{attrs:{"col":"6"}},[_c('CInput',{attrs:{"label":"Số điện thoại","placeholder":"Số điện thoại","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.Phone'],
                    },"invalid-feedback":_vm.inValidObject['agency.Phone']},model:{value:(_vm.agency.Phone),callback:function ($$v) {_vm.$set(_vm.agency, "Phone", $$v)},expression:"agency.Phone"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Thông tin tài khoản","placeholder":"0014101668888888 - NGUYEN THI HANG - Ngan Hang TMCP Phuong Dong (OCB)","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['agency.AcInfo'],
                    },"invalid-feedback":_vm.inValidObject['agency.AcInfo']},model:{value:(_vm.agency.AcInfo),callback:function ($$v) {_vm.$set(_vm.agency, "AcInfo", $$v)},expression:"agency.AcInfo"}})],1)],1),_c('div',{staticClass:"font-italic"},[_vm._v(" Lưu ý: Thông tin hóa đơn phục vụ mục đích in Hóa Đơn Bán Hàng, vui lòng cung cấp thông tin đầy đủ và chính xác! ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveAgency}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }